import React from "react";
import { Link } from "gatsby";

import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";

const FeaturedPostsList = ({ postData }) => {
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `/${process.env.GATSBY_DEFAULT_LANG}/`
      : `/${intl.locale}`;

  return (
    <div className="featured-posts-list">
      <ul className="featured-posts-list__list">
        {postData.map((post) => (
          <li key={post.node.id} className="featured-posts-list__list-item">
            <Link
              to={
                process.env.GATSBY_MULTILINGUAL === "true"
                  ? `${locale}${post.node.originalSlug}/`
                  : `/${post.node.originalSlug}/`
              }
            >
              <span className="featured-posts-list__category">
                {
                  post.node.categories.nodes.filter(
                    (category) => !category.slug.includes("featured-posts")
                  )[0].name
                }{" "}
                -{" "}
              </span>
              <span className="featured-posts-list__title">
                {post.node.title}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeaturedPostsList;
